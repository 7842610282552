#Godpromise404Banner {
  width: 100%;

  #GodpromiseBannerImg404 {
    position: relative;

    .GodpromiseBanner404 {
      display: flex !important;
      background-image: url();
      background-position: center center;
      width: 100%;
      height: 656px;
      display: block;
      opacity: 1;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;

      @include media-breakpoint-down(lg) {
        background-image: url();
        background-position: center center;
      }

      // @include media-breakpoint-down(sm) {
      //   background-image: url();
      //   background-position: center center;
      //   // height: 246px;
      //   // display: block;
      //   // opacity: 1;
      //   // -webkit-background-size: cover;
      //   // background-size: cover;
      //   // background-position: center;
      //   // height: auto;
      //   // padding-bottom: 56.2667%;
      //   // background-size: cover;
      //   // margin: 0px auto;
      //   // -webkit-background-size: cover;
      // }

      .blue {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        padding-bottom: 16px;

        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }

      .search-form {
        padding-top: 16px;
        padding-bottom: 22px;

        .search-input {
          color: #fff;
          position: relative;
          
          .form-control {
            width: 600px;
            height: 52px;
            font-size: 18px;
            background: transparent;
            color: #fff;
            border: 1px solid #fff;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #fff;
            }
            &::-moz-placeholder { /* Firefox 19+ */
              color: #fff;
            }
            &::-ms-input-placeholder { /* IE 10+ */
              color: #fff;
            }
            &::-moz-placeholder { /* Firefox 18- */
              color: #fff;
            }
            @include media-breakpoint-down(sm) {
              width: 326px;
            }

            @include media-breakpoint-down(xs) {
              width: 200px;
            }
          }
          
          
          .search-icon {
            position: absolute;
            top: 12px;
            right: 10px;
            background: transparent;

            .icon-search {
              position: absolute;
              top: 0px;
              right: 0px;
              color: #fff;
              cursor: pointer;
              font-size: 30px;
              &:hover{
                background: transparent !important;
              }
            }
          }

        }

      }

      .btnBox {
        a {
          background: transparent;
          // border: 1px solid #fff;
          color: #313131;

          &:hover {
            .icon-arrow-right {
              padding-left: 18px;
              margin-left: 0px;
            }
          }
        }

        .icon-arrow-right {
          padding-left: 0px;
        }
      }

      .backcall {
        color: #228A03 !important;
      }
    }
  }

}

#sermon {
  padding-bottom: 36px;

  .card-info {
    padding-left: 8px;
    padding-top: 10px;

    a {
      font-size: 18px !important;
      max-height:none;
      @include media-breakpoint-down(lg) {
        font-size: 16px !important;
      }
    }

  }
  .col-md-3{
    &:nth-of-type(n+5){
      display: none;
    }
  }
}

.btnBox {
  button {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #fff;
    color: #313131;

    &:hover {
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #fff;
      color: #313131;

    }
  }

  .backcall {
    a {
      color: #228A03 !important;
      &:hover {
        color: #228A03 !important;
      }
    }

    span {
      color: #228A03 !important;
      &:hover {
        color: #228A03 !important;
      }
    }
  }
}